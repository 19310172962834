<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>所属门店：</p>
          <select @change="getEmpList" v-model="form.fenpeimendianId">
            <option :value="0">全部</option>
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>员工：</p>
          <select @change="getData(1)" v-model="form.userId">
            <option :value="null">全部</option>
            <option :value="item.id" v-for="item in empList" :key="item.id">{{item.name}}</option>
          </select>
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list" v-if="!CalendarShow">
      <div class="list_d">
        <table>
          <thead>
            <tr>
              <td>员工</td>
              <td>上下班</td>
              <td>规则</td>
              <td>考勤时间</td>
              <td>状态</td>
              <td>详细</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dataList" :key="item.id">
              <td>{{item.xingming}}</td>
              <td>{{item.wokeing==1?"上班":"下班"}}</td>
              <td>{{item.guize}}</td>
              <td>{{getDateTime(item.nowDate)}}</td>
              <td><div class="clockStateTag" :class="getClass(state)" v-for="(state,i) in getClockState(item.clockState)" :key="i">{{state}}</div></td>
              <td>{{item.clockStateInfo}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Calendar v-if="CalendarShow" :userId="form.userId" :fenpeimendianId="form.fenpeimendianId"></Calendar>
    <div class="list_c">
      <div class="c_buttom">
        <button type="button" class="suc" @click="clockInCount" v-pow:4302>{{CalendarShow?"返回":"考勤统计"}}</button>
      </div>
      <Page :pageData="pageData" @resetData="resetData" v-if="!CalendarShow"></Page>
      <div class="clear"></div>
    </div>
    
  </div>
</template>

<script>
import axios from '@/http/axios'
import moment from 'moment'
import Calendar from './Calendar.vue'
export default {
  name: 'packageSettings',
  data() {
    return {
      CalendarShow:false,
      dataList:[],
      storeList:[],
      empList:[],
      form:{
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
        userId:null,
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
      
     
    }
  },
  created() {
     this.getStore()
  },
  components: {Calendar},
  mounted() {},
  methods: {
    getClass(str){
      if(str=="迟到"||str=="早退") return 'yichang'
      else return ''
    },
    getClockState(str){
      if(str) return str.split(',')
      else return []
    },
    clockInCount(){
      this.CalendarShow=!this.CalendarShow
    },
    getDateTime(sr){
      if(sr) return moment(sr).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    returnLBClass(lb){
      if(lb==1){
        return 'suc'
      }
      else{
        return 'err'
      }
    },
    returnLB(lb){
      if(lb==1){
        return '正常'
      }
      else{
        return '撤销'
      }
    },
    delOneData(id,userName){
      this.$trueOrFalse({
        title: '删除提示',
        content: `确认撤销 ${userName} 的这条考勤记录吗?`,
        torf: false,
        suc:()=>{
          axios.post('/kaoqin/delKQJL',{id,userName}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
          this.getEmpList()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getEmpList(){
      axios.post('/emp/list',{fenpeimendianId:this.form.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.empList=res.data.map(e=>{
            return{
              id:e.id,
              name:e.xingming,
            }
          })
          this.form.userId=null
          this.getData(1)
        }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
      })
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/kaoqin/JLlistPage',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.pageData.total=res.total
        }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
      })
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
  },
}
</script>
